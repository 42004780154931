import React from 'react'
// import { StaticImage } from "gatsby-plugin-image"

export const IntuitLogo = (): React.ReactElement => {
    return (
        <>
            <span className="sr-only">Intuit</span>
            <svg width="166" height="33" viewBox="0 0 166 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M85.2185 19.0956C85.2185 27.126 91.9524 33 100.509 33C109.066 33 115.808 27.126 115.808 19.0956V0.646203H107.523V18.1445C107.523 22.4791 104.483 25.398 100.487 25.398C96.4923 25.398 93.4509 22.4937 93.4509 18.1445V0.646203H85.1672L85.2185 19.0956ZM137.944 8.03035H147.83V32.3901H156.115V8.03035H166V0.631678H137.944V8.03035ZM132.59 0.631678H124.305V32.3901H132.59V0.631678ZM51.749 8.03035H61.6351V32.3901H69.9188V8.03035H79.7981V0.631678H51.749V8.03035ZM8.28373 0.631678H0V32.3901H8.28373V0.631678ZM47.3862 13.897C47.3862 5.86665 40.6511 0 32.0876 0C23.524 0 16.789 5.86665 16.789 13.897V32.3901H25.0739V14.8917C25.0739 10.5571 28.1142 7.63827 32.1093 7.63827C36.1055 7.63827 39.1458 10.5426 39.1458 14.8917V32.3901H47.4307L47.3793 13.897H47.3862Z" fill="#236CFF" />
            </svg>
        </>
    )
}
