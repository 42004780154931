import React from "react"

export const Footer = () => {
    return (
        <>
            <div className="text-center p-4 py-12 text-[12px]">2022 © Numaproj Authors. All rights reserved.</div>
        </>
    )
}

export default Footer